import { ApplicationElement, html } from '@beunity/design';
import { AppConfig } from 'src/configuration';
import { $d } from 'src/utils';
import CommentBinder from 'src/data_binders/comment_binder';
import CardBinder from 'src/data_binders/card_binder';
import pusher from 'src/newpusher';
import I18n from 'i18n';
import style from './style.lit.css';

function setupPusherForCard(channel, cardElement) {
  if (!channel) { return; }

  channel.bind('comment:create', (data) => {
    const $comment = $(data.html);
    if (data.parent_id) {
      $d('.card-comment', data.parent_id)
        .trigger('comment:add')
        .find('.js-comments-list:first')
        .append($comment);
    } else {
      $(`bu-card[cardId=${data.card_id}]`)
        .find('.js-comments-list:first')
        .prepend($comment)
        .trigger('comment:add');
    }
  });

  channel.bind('comment:destroy', (data) => {
    $d('.card-comment', data.id).hide(400).trigger('comment:remove', data.count);
  });

  channel.bind('comment:update', (data) => {
    CommentBinder($d('.card-comment', data.id), data);
  });

  channel.bind('card:update', (data) => {
    CardBinder(cardElement, data);
  });

  channel.bind('card:poll_update', (data) => {
    CardBinder(cardElement, data);
  });

  channel.bind('card:date_update', (data) => {
    const { totalCount, users } = data;

    const wrapper = cardElement.querySelector('bu-date-finder-option-wrapper');
    wrapper.totalCount = totalCount;

    data.options.forEach((o) => {
      const optionElement = wrapper.querySelector(`bu-date-finder-option[rid="${o.id}"]`);
      optionElement.users = o.voters.map(id => users[id]);
      optionElement.count = o.count;
      optionElement.totalCount = o.totalCount;
    });
  });

  channel.bind('card:organize_update', (data) => {
    CardBinder(cardElement, data);
  });
}

/**
 * Card wrapper
 *
 * This is V1 of the card web component implementation
 *
 * @prop cardId - Card Id
 * @prop userId - User Id (Author, owner)
 * @prop type - Card short type (event, news, organize, ...)
 * @prop href - Link to card full view
 * @prop kind - Additional kind information (label on right - buying, looking, offering, selling)
 * @prop subscribeValue - Subscribed status (boolean). Used inside bu-card-menu
 * @prop bookmarkValue - Bookmarked status (boolean). Used inside bu-card-menu
 * @prop hiddenValue - Hidden status (boolean). Used inside bu-card-menu
 * @prop pinValue - Pinned status (boolean). Also used inside bu-card-menu
 * @prop commentsCount - Comments count (number)
 * @prop channelId - Pusher channelId for websockets
 * @prop isInitSubscriber - Determinate if user was in any point subscribed/unsubscribed to a card (boolean)
 * @prop collapseLocked - Disable collapse for this card (boolean)
 * @prop address - Event address information
 * @prop attendeesLimit - Maximum number of attendees for event card (number)
 * @prop attendantsCountTaken - Current number of attendees for event card (number)
 * @prop attendingState - Current attending state
 * @prop startsAt - Event start information
 * @prop endsAt - Event end information
 * @prop allDay - Is event duration all day
 * @prop preheaderTitle - Indicates to which group forum post belongs
 * @prop {string | 'primary'} theme - Style attribute set when element is pinned
 * @prop hiddenNotice - Hidden notice if card is hidden
 * @prop isFullView - Internal state. Automatically set if card is in full view
 * @prop isCollapsed - Internal state. Body and the footer are not visible if it is agenda list with past events
 *
 * @slot / - Slot for card content
 *
 */
export default class Card extends ApplicationElement {
  static styles = [ApplicationElement.styles, style];

  static properties = {
    cardId: { type: Number },
    userId: { type: Number },
    type: { type: String },
    href: { type: String },
    kind: { type: String },
    commentsCount: { type: Number },
    channelId: { type: String },
    isInitSubscriber: { type: Boolean },
    collapseLocked: { type: Boolean },
    theme: { type: String, reflect: true },
    hiddenNotice: { type: Number },

    // States
    pinValue: { type: Boolean, reflect: true },
    subscribeValue: { type: Boolean, reflect: true },
    bookmarkValue: { type: Boolean, reflect: true },
    hiddenValue: { type: Boolean, reflect: true },

    // EventCard
    address: { type: String },
    attendeesLimit: { type: Number },
    attendantsCountTaken: { type: Number },
    attendingState: { type: String },
    startsAt: { type: Date },
    endsAt: { type: Date },
    allDay: { type: Boolean },

    // CardHeader
    createdAt: { type: Date },
    isOfficial: { type: Boolean },
    publisher: { type: String },
    publisherUrl: { type: String },

    // CardPreheader
    preheaderTitle: { type: String },

    isFullView: { state: true },
    isCollapsed: { state: true },
    menu: { state: true },
  };

  static watchedProps = ['subscribeValue', 'bookmarkValue', 'pinValue'];

  constructor() {
    super();
    this.isFullView = /.+_cards\/\d+/.test(window.location.href);
    this.isCollapsed = this.shouldCollapse;
  }

  connectedCallback() {
    super.connectedCallback();
    this.channel = pusher?.subscribe(this.channelId);
    setupPusherForCard(this.channel, this);
    this.setupEvents();
    this.isCurrentUserOwner && this.classList.add('card-owner');
  }

  firstUpdated() {
    /* Update card-menu component */
    /* Once we will render card-menu inside card component we will be able to remove this code */
    const { menu } = this;

    if (!menu) return;

    menu.cardTitle = this.querySelector('[slot="title"]').innerText;
    menu.subscribeValue = this.subscribeValue;
    menu.bookmarkValue = this.bookmarkValue;
    menu.pinValue = this.pinValue;
    menu.hiddenValue = this.hiddenValue;
    /* END Update card-menu component */
  }

  willUpdate(changedProperties) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key] of changedProperties.entries()) {
      /* Subscribe user to card if performing card actions unless user already subscribed/unsubscribed in the past */
      this.shouldInitializeSubscriber(key) && this.initializeSubscriber();

      /* Update card-menu component */
      /* Once we will render card-menu inside card component we will be able to remove this code */
      if (this.menu && this.constructor.watchedProps.includes(key)) {
        this.menu[key] = this[key];
      }
      /* END Update card-menu component */
      if (changedProperties.has('pinValue')) {
        this.setTheme();
      }
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    pusher?.unsubscribe(this.channelId);
    this.channel = null;
  }

  /** @internal */
  get shouldCollapse() {
    return /communities\/\d+.+agenda\/past/.test(window.location.href)
          || /embed\/.+&type=event/.test(window.location.href);
  }

  /** @internal */
  get isSmartCollapsed() {
    return this.classList.contains('card-smart-collapsed');
  }

  /** @internal */
  get isCommunityStream() {
    return /communities\/\d+\/(?!groups)/.test(window.location.href);
  }

  /** @internal */
  get isGroupStream() {
    return /communities\/\d+\/groups\/\d+/.test(window.location.href);
  }

  /** @internal */
  get isAgendaStream() {
    return /communities\/\d+.+agenda/.test(window.location.href);
  }

  /** @internal */
  get isPostStream() {
    return /communities\/\d+.+post/.test(window.location.href);
  }

  /** @internal */
  get isForumStream() {
    return /communities\/\d+.+forum/.test(window.location.href);
  }

  /** @internal */
  get isMarketplaceStream() {
    return /communities\/\d+.+marketplace/.test(window.location.href);
  }

  /** @internal */
  get isCommunityPostsOrForumStream() {
    return this.isCommunityStream && (this.isPostStream || this.isForumStream);
  }

  /** @internal */
  get isGroupForumStream() {
    return this.isGroupStream && !this.isAgendaStream;
  }

  /** @internal */
  get isAttendantsSlotFull() {
    return this.attendantsCountTaken >= this.attendeesLimit;
  }

  /** @internal */
  get shouldRenderPreheader() {
    return !this.isSmartCollapsed && !(this.isAgendaStream && this.type === 'event') && !(this.isMarketplaceStream && this.type === 'marketplace');
  }

  /** @internal */
  get kindTranslated() {
    return I18n.t(`card.kind.${this.kind}`);
  }

  /** @internal */
  get pollCardDeselectVotes() {
    return this.querySelector('.js-poll-clear-btn');
  }

  /** @internal */
  get isCurrentUserOwner() {
    return String(this.userId) === String(AppConfig.user.id);
  }

  setTheme() {
    this.theme = (this.pinValue && this.isCommunityPostsOrForumStream) || (this.pinValue && this.isGroupForumStream) ? 'primary' : null;
  }

  renderPreheader() {
    return html`
      <bu-card-preheader .href=${this.href}
                         .kind=${this.kind}
                         .type=${this.type}
                         .preheaderTitle=${this.preheaderTitle}>
      </bu-card-preheader>
    `;
  }

  renderHeader() {
    return html`
      <bu-card-header .createdAt=${this.createdAt}
                      .isOfficial=${this.isOfficial}
                      .publisher=${this.publisher}
                      .publisherUrl=${this.publisherUrl}>
        <slot name="avatar" slot="avatar"></slot>
        <slot name="breadcrumbs" slot="breadcrumbs"></slot>
      </bu-card-header>
    `;
  }

  renderKind() {
    return html`
      <span class="card-kind">
        ${this.kindTranslated}
      </span>
    `;
  }

  renderAttendeesInfo() {
    return html`
      <a href='/event_cards/${this.cardId}/participants'>
        <ef-icon-block icon='clipboard-user'>
          <span>${this.attendantsCountTaken}</span><span ?hidden=${!this.attendeesLimit}>/${this.attendeesLimit}</span>
        </ef-icon-block>
      </a>
    `;
  }

  renderAttendeesSlotsFullLabel() {
    return html`
      <ef-label theme='danger'>Booked out</ef-label>
    `;
  }

  renderEventDetails() {
    return html`
      <ef-grid-list class='event-details' col='0' gap='large'>
        <ef-icon-block icon='map-marker-alt'>${this.address}</ef-icon-block>
        <ef-time-range from=${this.startsAt} to=${this.endsAt} dateOnly=${this.allDay}></ef-time-range>
        ${this.attendantsCountTaken ? this.renderAttendeesInfo() : null}
        ${this.isAttendantsSlotFull ? this.renderAttendeesSlotsFullLabel() : null}
      </ef-grid-list>
    `;
  }

  setupEvents() {
    this.addEventListener('fragmentInclude:replaced', this);
    this.addEventListener('ajax:success', this);
    this.addEventListener('toggle-button:updated', this);
  }

  handleEvent(e) {
    const method = {
      'ajax:beforeSend': 'onAjaxBeforeSend',
      'ajax:success': 'onAjaxSuccess',
      'ajax:error': 'onAjaxError',
      'ajax:complete': 'onAjaxComplete',
      'ajax:stopped': 'onAjaxComplete',
      'fragmentInclude:replaced': 'onFragmentIncludeReplaced',
      'toggle-button:updated': 'onButtonToggle',
    }[e.type];

    this[method](e);
  }

  isTypeOf(type) {
    return this.type === type;
  }

  onAjaxSuccess({ target }) {
    if (this.isTypeOf('poll') && target.tagName === "EF-POLL-ITEM") {
      this.pollCardOnVote();
    } else if (this.isTypeOf('poll') && target.href?.includes('deselect_votes')) {
      this.pollCardOnDeselectVotes();
    }
  }

  onFragmentIncludeReplaced(e) {
    const currentUserVoted = !!e.target.querySelector("[checked]");
    this.isTypeOf('poll') && currentUserVoted && this.pollCardShowDeselectVotesControl();
  }

  onButtonToggle(e) {
    const prop = e.target.as;

    if (prop === 'subscribeValue') {
      this.subscribeValue = e.target.active;
    } else if (prop === 'pinValue') {
      this.pinValue = e.target.active;
    } else if (prop === 'bookmarkValue') {
      this.bookmarkValue = e.target.active;
    }
  }

  pollCardOnDeselectVotes() {
    this.pollCardDeselectVotes.classList.add('invisible');
  }

  pollCardOnVote() {
    this.pollCardShowDeselectVotesControl();
  }

  pollCardShowDeselectVotesControl() {
    this.pollCardDeselectVotes.classList.remove('invisible');
  }

  shouldInitializeSubscriber(key) {
    return ((key === 'subscribeValue' && this.subscribeValue === true)
            || (key === 'bookmarkValue' && this.bookmarkValue === true)
            || (key === 'attendingState' && this.attendingState === 'going'))
            && !this.isInitSubscriber;
  }

  initializeSubscriber() {
    this.subscribeValue = true;
    this.isInitSubscriber = true;
  }

  updateCommentCount(num) {
    this.commentsCount += num;
  }

  showContent() {
    this.isCollapsed = false;
  }

  renderHiddenNoticeMsg() {
    // i18n-tasks-use t("card.hidden_reason.reason_10")
    // i18n-tasks-use t("card.hidden_reason.reason_20")
    // i18n-tasks-use t("card.hidden_reason.reason_30")
    // i18n-tasks-use t("card.hidden_reason.reason_40")
    // i18n-tasks-use t("card.hidden_reason.reason_50")
    // i18n-tasks-use t("card.hidden_reason.reason_60")

    let hiddenReasonNoticeTranslationKey = 'card.hidden_reason_notice_group';

    if (this.isCommunityStream) {
      hiddenReasonNoticeTranslationKey = 'card.hidden_reason_notice';
    }

    return html`
      <div class="notice">
        <h4>${I18n.t(`card.hidden_reason.reason_${this.hiddenNotice}`)}</h4>
        <span>${I18n.t(hiddenReasonNoticeTranslationKey)}</span>
      </div>
  `;
  }

  renderBody() {
    return html`
      <div class="card-body">
        <slot name="title" slot="title"></slot>
        ${this.type === 'event' ? this.renderEventDetails() : null}
        <slot></slot>
        <slot name='body-bottom'></slot>
      </div>
    `;
  }

  renderFooter() {
    return html`
      <div class="card-footer">
        <slot name='bottom'></slot>
      </div>
    `;
  }

  renderShowContentTrigger() {
    return html`
      <button @click=${this.showContent} class="toggle-content">
        <ef-icon name="chevron-down"></ef-icon>
      </button>
    `;
  }

  render() {
    return html`
      ${this.kind && this.renderKind()}
      ${this.shouldRenderPreheader ? this.renderPreheader() : null}
      ${this.hiddenNotice ? this.renderHiddenNoticeMsg() : null}
      ${this.renderHeader()}
      ${!this.isCollapsed ? this.renderBody() : null}
      ${!this.isCollapsed ? this.renderFooter() : null}
      ${this.isCollapsed ? this.renderShowContentTrigger() : null}
    `;
  }
}

customElements.get('bu-card') || customElements.define('bu-card', Card);
