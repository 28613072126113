import I18n from 'i18n';
import Snack from '@effectivastudio/snackbar';
import { $d } from 'src/utils';
import DataBinder from 'lib/data_binder';
import OrganizeBinder from './organize_binder';

function CardSchema(d) {
  const _ = {};

  if ('poll_options' in d) {
    d.poll_options.forEach((o) => {
      const pollItem = document.querySelector(`ef-poll-item[rid="${o.id}"]`);
      Object.assign(pollItem, {
        percent: o.percent,
      });
    });
  }

  if ('organize_item' in d) {
    OrganizeBinder($d('.js-option', d.organize_item.id), d.organize_item);
  }

  if ('card_comments_count' in d) {
    if (String(d.card_comments_count).match(/\+|-/)) {
      _.text = this.$('.js-comment > bu-icon-block').innerText;
      _.count = parseInt(_.text, 10) || 0;
      d.card_comments_count = _.count + parseInt(d.card_comments_count, 10);
    }

    d.hasComments = d.card_comments_count >= 1;
    d.card_comments_count = I18n.t('card.comment_label_html', { count: d.card_comments_count });
  }

  if ('poll_voters_count' in d) {
    d.poll_voters_count = I18n.t('voting.x_members_voted', { count: d.poll_voters_count });
  }

  if ('organize_assignees_count' in d) {
    d.organize_assignees_count = I18n.t('assigning.x_members_assigned', { count: d.organize_assignees_count });
  }

  if ('marketplace_card_available' in d) {
    const marketplacePrice = this.el.querySelector('ef-marketplace-price');
    marketplacePrice.available = d.marketplace_card_available;
  }

  if ('engagement_state' in d) {
    this.html('.state-wrapper', d.engagement_state);
  }

  if (d.snackMsg) {
    if (d.snackMsg === 'attend') {
      Snack({ text: d.attendMsg });
    } else if (d.snackMsg === 'organize_assign') {
      Snack({ text: I18n.t('snack.assign.success_msg') });
    } else if (d.snackMsg === 'organize_unassign') {
      Snack({ text: I18n.t('snack.unassign.success_msg') });
    } else if (d.snackMsg === 'error') {
      Snack({
        text: I18n.t('snack.generic_error_msg'),
        customClass: 'snackbar-danger',
      });
    } else {
      Snack({
        text: d.snackMsg,
        customClass: 'snackbar-danger',
      });
    }
  }

  this
    .data(':root', 'stamp', d.updated_at)
    .attr('bu-card-like', 'count', d.card_likes_count)
    .html('.js-comment bu-icon-block', d.card_comments_count)
    .text('.js-total-poll-voter-count', d.poll_voters_count)
    .text('.js-organize-assignees-count', d.organize_assignees_count);
}

export default DataBinder.create(CardSchema);
